import React, { useState, useEffect } from "react";
import { fetchProfile } from "../services/api";
import { useParams } from "react-router-dom";

const DisplayUser = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [allowData, setAllowData] = useState(null); // Tracks allowDataSharing
  const { id, userType } = useParams();

  // Mapping database keys to Thai labels
  const fieldLabels = {
    walkInID: "ID",
    title: "คำนำหน้า",
    firstName: "ชื่อ",
    lastName: "นามสกุล",
    nickname: "ชื่อเล่น",
    batch: "รุ่น",
    phoneNumber: "เบอร์โทรศัพท์",
    email: "อีเมล",
    occupation: "อาชีพ",
    memberID: "รหัสสมาชิก",
    gender: "เพศ",
    mobilePhone: "เบอร์มือถือ",
    address: "ที่อยู่",
    district: "เขต/อำเภอ",
    province: "จังหวัด",
    postalCode: "รหัสไปรษณีย์",
    memberType: "ประเภทสมาชิก",
    admissionYear: "ปีที่สมัคร",
  };

  const excludeKeys = ["allowDataSharing"]; // Fields to exclude

  useEffect(() => {
    const checkAllowDataSharing = async () => {
      try {
        const profileData = await fetchProfile(id, userType);

        if (profileData.allowDataSharing) {
          setAllowData(true); // Allow data sharing
          setProfile(profileData);
        } else {
          setAllowData(false); // Deny data sharing
        }
      } catch (err) {
        setError("ไม่สามารถโหลดข้อมูลผู้ใช้งานได้");
      } finally {
        setLoading(false);
      }
    };

    checkAllowDataSharing();
  }, [id, userType]);

  if (loading) return <p>กำลังโหลดข้อมูลผู้ใช้งาน...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  // Display Red Cross and Message if allowDataSharing is false
  if (allowData === false) {
    return (
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto text-center">
        <div className="text-red-500 text-6xl font-bold">✖</div>
        <p className="text-red-500 text-lg mt-2">
          ทางผู้ร่วมงานไม่สะดวกให้ข้อมูลส่วนบุคคล
        </p>
      </div>
    );
  }

  // Render user profile if allowDataSharing is true
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">รายละเอียดผู้ร่วมงาน</h2>
      <div>
        {Object.entries(profile)
          .filter(([key]) => !excludeKeys.includes(key)) // Exclude specific keys
          .map(([key, value]) => (
            <p key={key} className="mb-2">
              <strong className="capitalize">{fieldLabels[key] || key}:</strong>{" "}
              {value || "ไม่มีข้อมูล"}
            </p>
          ))}
      </div>
    </div>
  );
};

export default DisplayUser;
