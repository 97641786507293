import React, { useState, useEffect } from "react";
import { fetchProfile, updateProfile, fetchQRCode, logPrintRequest } from "../services/api";
import { useParams } from "react-router-dom";

const ProfilePage = () => {
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const { id, userType } = useParams();

  // Mapping database keys to Thai labels
  const fieldLabels = {
    walkInID: "ID",
    title: "คำนำหน้า",
    firstName: "ชื่อ",
    lastName: "นามสกุล",
    nickname: "ชื่อเล่น",
    batch: "รุ่น",
    phoneNumber: "เบอร์โทรศัพท์",
    email: "อีเมล",
    occupation: "อาชีพ",
    memberID: "รหัสสมาชิก",
    gender: "เพศ",
    mobilePhone: "เบอร์มือถือ",
    address: "ที่อยู่",
    district: "เขต/อำเภอ",
    province: "จังหวัด",
    postalCode: "รหัสไปรษณีย์",
    memberType: "ประเภทสมาชิก",
    admissionYear: "ปีที่สมัคร",
  };

  // Read-only fields
  const readOnlyFields = ["memberID", "walkInID", "title", "firstName", "lastName", "gender", "nickname", "batch"];
  const nullableFields = ["nickname", "batch", "email", "occupation", "mobilePhone", "address", "district", "province", "postalCode"];
  const [showPrintButton, setShowPrintButton] = useState(true);

  useEffect(() => {
    const loadProfile = async () => {
      try {
        const baseUrl = window.location.origin;
        const profileData = await fetchProfile(id, userType);
        setProfile(profileData);
        setFormData(profileData);

        const qrCode = await fetchQRCode(id, userType, baseUrl);
        setQrCodeUrl(qrCode);
        setShowPrintButton(true);
      } catch (err) {
        setError("ไม่สามารถโหลดข้อมูลโปรไฟล์ได้");
      }
    };
    loadProfile();
  }, [id, userType]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = async () => {
    try {
      const updatedProfile = await updateProfile(id, userType, formData);
      setProfile(updatedProfile);
      setMessage("บันทึกโปรไฟล์สำเร็จ");
      setIsEditing(false);
    } catch (err) {
      setError("ไม่สามารถบันทึกข้อมูลได้");
    }
  };

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      allowDataSharing: e.target.checked, // Checked means "Allow Data Sharing"
    });
  };
  

  const handlePrintQRCode = async () => {
    try {
      // Update the allowDataSharing status based on the current checkbox state
      const updatedData = { ...formData, allowDataSharing: formData.allowDataSharing ?? false };
  
      // Save the updated profile
      await updateProfile(id, userType, updatedData);
      setProfile((prev) => ({ ...prev, allowDataSharing: updatedData.allowDataSharing }));
  
      // Log the print request
      await logPrintRequest({
        id: profile.walkInID || profile.memberID,
        nickname: profile.nickname || "-",
        batch: profile.batch || "-",
        userType: userType || "-",
        printRequest: false,
        url: null, // Backend constructs the URL
        timestamp: new Date().toISOString(),
      });
  
      setMessage("การพิมพ์ได้รับการบันทึกเรียบร้อยแล้ว");
      setShowPrintButton(false);
    } catch (err) {
      setError("ไม่สามารถบันทึกคำขอพิมพ์ได้");
    }
  };
  

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  if (!profile) {
    return <p>กำลังโหลดข้อมูลโปรไฟล์...</p>;
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">รายละเอียดโปรไฟล์</h2>

      {message && <p className="text-green-500 mb-4">{message}</p>}
      {error && <p className="text-red-500 mb-4">{error}</p>}

      {isEditing ? (
        <div>
          {Object.keys(formData)
            .filter((key) => key !== "allowDataSharing"&& key !== "admissionYear" && key !== "memberType") // Exclude allowDataSharing
            .map((key) => (
              <div className="mb-4" key={key}>
                <label className="block text-gray-700 mb-2 capitalize">{fieldLabels[key] || key}</label>
                <input
                  type="text"
                  name={key}
                  value={formData[key] || ""}
                  onChange={handleInputChange}
                  disabled={readOnlyFields.includes(key)} // Disable read-only fields
                  required={!nullableFields.includes(key)}
                  className={`w-full px-4 py-2 border rounded-md focus:ring-2 ${
                    readOnlyFields.includes(key) ? "bg-gray-200 cursor-not-allowed" : "focus:ring-blue-400"
                  }`}
                />
              </div>
            ))}
          <button
            onClick={handleSave}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md font-semibold hover:bg-blue-600 transition-colors mt-4"
          >
            บันทึกการเปลี่ยนแปลง
          </button>
          <button
            onClick={() => {
              setIsEditing(false);
              setFormData(profile); // Revert changes
            }}
            className="w-full bg-gray-300 text-black py-2 px-4 rounded-md hover:bg-gray-400 transition-colors mt-2"
          >
            ยกเลิก
          </button>
        </div>
      ) : (
        <div>
          {Object.entries(profile)
            .filter(([key]) => key !== "allowDataSharing") // Exclude allowDataSharing
            .map(([key, value]) => (
              <p key={key} className="mb-2">
                <strong className="capitalize">{fieldLabels[key] || key}:</strong> {value || "-"}
              </p>
            ))}

{profile.allowDataSharing === null && (
  <div className="mb-4">
    <label className="inline-flex items-center">
      <input
        type="checkbox"
        checked={formData.allowDataSharing || false} // Default to false if null
        onChange={(e) =>
          setFormData({
            ...formData,
            allowDataSharing: e.target.checked, // Checked means true
          })
        }
        className="form-checkbox h-5 w-5 text-blue-600"
      />
      <span className="ml-2 text-gray-700">คุณอนุญาตให้แชร์ข้อมูล ชื่อเบอร์โทรอีเมล ให้ Supporter หรือไม่</span>
    </label>
  </div>
)}



          <button
            onClick={() => setIsEditing(true)}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md font-semibold hover:bg-blue-600 mt-4"
          >
            แก้ไขโปรไฟล์
          </button>



          {qrCodeUrl && (
  <div className="mt-6 text-center">
    <h3 className="text-lg font-bold mb-2">QR Code</h3>
    <img src={qrCodeUrl} alt={`${userType} QR Code`} className="inline-block w-48 h-48 mb-4" />
    {showPrintButton && (
      <button
        onClick={handlePrintQRCode}
        className="bg-green-500 text-white py-2 px-4 rounded-md font-semibold hover:bg-green-600"
      >
        พิมพ์ QR Code
      </button>
    )}
  </div>
)}

    </div>
  )
}
</div>
)
};
export default ProfilePage;