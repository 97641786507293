import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchSupporterById } from "../services/api";

const Display = () => {
  const { supporterId } = useParams(); // ดึง ID จาก URL
  const [supporter, setSupporter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchSupporter = async () => {
      try {
        const data = await fetchSupporterById(supporterId);
        setSupporter(data);
        setLoading(false);
      } catch (err) {
        setError("ไม่สามารถโหลดข้อมูลผู้สนับสนุนได้");
        setLoading(false);
      }
    };

    fetchSupporter();
  }, [supporterId]);

  const isNullOrUndefinedOrDbNull = (value) => {
    return !value || value === "NULL";
  };

  if (loading) return <p>กำลังโหลดข้อมูลผู้สนับสนุน...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">รายละเอียดผู้สนับสนุน</h2>
      <table className="min-w-full border-collapse border border-gray-300">
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2 font-bold">รหัส</td>
            <td className="border border-gray-300 px-4 py-2">{supporter.id}</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2 font-bold">เวลาที่ส่งข้อมูล</td>
            <td className="border border-gray-300 px-4 py-2">
            {new Date(supporter.submissionTime).toLocaleString("th-TH", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
})}
น.

            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2 font-bold">ชื่อบริษัท</td>
            <td className="border border-gray-300 px-4 py-2">
              {isNullOrUndefinedOrDbNull(supporter.companyName) ? "-" : supporter.companyName}
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2 font-bold flex">Facebook <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
<path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path><path fill="#fff" d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"></path>
</svg></td>
            <td className="border border-gray-300 px-4 py-2">
              {isNullOrUndefinedOrDbNull(supporter.facebookId) ? (
                "-"
              ) : (
                <a
                  href={supporter.facebookId}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {isNullOrUndefinedOrDbNull(supporter.facebookLink)
                    ? "ลิงก์ Facebook"
                    : supporter.facebookLink}
                </a>
              )}
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2 font-bold flex">Line <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
<path fill="#00c300" d="M12.5,42h23c3.59,0,6.5-2.91,6.5-6.5v-23C42,8.91,39.09,6,35.5,6h-23C8.91,6,6,8.91,6,12.5v23C6,39.09,8.91,42,12.5,42z"></path><path fill="#fff" d="M37.113,22.417c0-5.865-5.88-10.637-13.107-10.637s-13.108,4.772-13.108,10.637c0,5.258,4.663,9.662,10.962,10.495c0.427,0.092,1.008,0.282,1.155,0.646c0.132,0.331,0.086,0.85,0.042,1.185c0,0-0.153,0.925-0.187,1.122c-0.057,0.331-0.263,1.296,1.135,0.707c1.399-0.589,7.548-4.445,10.298-7.611h-0.001C36.203,26.879,37.113,24.764,37.113,22.417z M18.875,25.907h-2.604c-0.379,0-0.687-0.308-0.687-0.688V20.01c0-0.379,0.308-0.687,0.687-0.687c0.379,0,0.687,0.308,0.687,0.687v4.521h1.917c0.379,0,0.687,0.308,0.687,0.687C19.562,25.598,19.254,25.907,18.875,25.907z M21.568,25.219c0,0.379-0.308,0.688-0.687,0.688s-0.687-0.308-0.687-0.688V20.01c0-0.379,0.308-0.687,0.687-0.687s0.687,0.308,0.687,0.687V25.219z M27.838,25.219c0,0.297-0.188,0.559-0.47,0.652c-0.071,0.024-0.145,0.036-0.218,0.036c-0.215,0-0.42-0.103-0.549-0.275l-2.669-3.635v3.222c0,0.379-0.308,0.688-0.688,0.688c-0.379,0-0.688-0.308-0.688-0.688V20.01c0-0.296,0.189-0.558,0.47-0.652c0.071-0.024,0.144-0.035,0.218-0.035c0.214,0,0.42,0.103,0.549,0.275l2.67,3.635V20.01c0-0.379,0.309-0.687,0.688-0.687c0.379,0,0.687,0.308,0.687,0.687V25.219z M32.052,21.927c0.379,0,0.688,0.308,0.688,0.688c0,0.379-0.308,0.687-0.688,0.687h-1.917v1.23h1.917c0.379,0,0.688,0.308,0.688,0.687c0,0.379-0.309,0.688-0.688,0.688h-2.604c-0.378,0-0.687-0.308-0.687-0.688v-2.603c0-0.001,0-0.001,0-0.001c0,0,0-0.001,0-0.001v-2.601c0-0.001,0-0.001,0-0.002c0-0.379,0.308-0.687,0.687-0.687h2.604c0.379,0,0.688,0.308,0.688,0.687s-0.308,0.687-0.688,0.687h-1.917v1.23H32.052z"></path>
</svg></td>
            <td className="border border-gray-300 px-4 py-2">
              {isNullOrUndefinedOrDbNull(supporter.lineId ) ? (
                "-"
              ) : (
                <a
                  href={supporter.lineLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {isNullOrUndefinedOrDbNull(supporter.lineId) ? "ลิงก์ Line" : supporter.lineId}
                </a>
              )}
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2 font-bold">เว็บไซต์</td>
            <td className="border border-gray-300 px-4 py-2">
              {isNullOrUndefinedOrDbNull(supporter.website) ? (
                "-"
              ) : (
                <a
                  href={supporter.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {supporter.website}
                </a>
              )}
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2 font-bold">ผู้เข้าร่วม</td>
            <td className="border border-gray-300 px-4 py-2">
              {isNullOrUndefinedOrDbNull(supporter.participant1) ? "-" : supporter.participant1}
              <br />
              {isNullOrUndefinedOrDbNull(supporter.participant2) ? "-" : supporter.participant2}
              <br />
              {isNullOrUndefinedOrDbNull(supporter.participant3) ? "-" : supporter.participant3}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Display;
