import axios from "axios";

const BASE_URL = "https://api-laitung.pedd.tech/api";

// Function to register a new walk-in
export const registerWalkIn = async (walkInData) => {
  try {
    const response = await axios.post(`${BASE_URL}/walkins/register`, walkInData, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || "Failed to register walk-in";
  }
};
export const registerMember = async (memberData) => {
  try {
    const response = await axios.post(`${BASE_URL}/members/register`, memberData, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || "Failed to register member";
  }
};
export const convertToMember = async (memberData) => {
  try {
    const response = await axios.post(`${BASE_URL}/members/convert`, memberData, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || "Failed to convert to member";
  }
};
export const authenticateMember = async (firstName, lastName) => {
  try {
    const response = await axios.post(`${BASE_URL}/members/auth`, { firstName, lastName });
    return response.data;
  } catch (error) {
    throw error.response?.data || "Authentication failed";
  }
};
export const fetchProfile = async (id, userType) => {
  try {
    const endpoint =
      userType === "member"
        ? `${BASE_URL}/Members/profile/${id}?type=member`
        : `${BASE_URL}/WalkIns/profile/${id}?type=walkin`;

    const response = await fetch(endpoint, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch profile");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};
export const updateProfile = async (id, userType, data) => {
  const endpoint = userType === "member" 
    ? `${BASE_URL}/Members/profile/${id}` 
    : `${BASE_URL}/WalkIns/profile/${id}`;
  
  const response = await fetch(`${endpoint}`, {
    method: "PUT", // Use PUT or PATCH for updates
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`Failed to update profile: ${response.statusText}`);
}

// Handle plain text responses
const contentType = response.headers.get("content-type");
if (contentType && contentType.includes("application/json")) {
    return await response.json(); // Parse JSON
}

// For plain text responses
return await response.text();
};
export const fetchQRCode = async (id, userType) => {
  try {
    const endpoint =
      userType === "member"
        ? `${BASE_URL}/Members/${userType}/${id}/qrcode`
        : `${BASE_URL}/WalkIns/${userType}/${id}/qrcode`;
    const response = await axios.get(`${endpoint}`, {
      responseType: "arraybuffer", // Ensures binary data is fetched
    });

    // Convert the binary response to a Base64 string
    const base64 = btoa(
      new Uint8Array(response.data)
        .reduce((data, byte) => data + String.fromCharCode(byte), "")
    );

    return `data:image/png;base64,${base64}`;
  } catch (error) {
    throw new Error("Failed to fetch QR code");
  }
};
export const logPrintRequest = async (printData, baseUrl) => {
  try {
    const baseUrl = window.location.origin;
    const response = await axios.post(
      `${BASE_URL}/Print?baseUrl=${encodeURIComponent(baseUrl)}`,
      printData,
      { headers: { "Content-Type": "application/json" } }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || "Failed to log print request";
  }
};
export const fetchSupporterById = async (supporterId) => {
  try {
    const response = await axios.get(`${BASE_URL}/Supporter/${supporterId}`);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch supporter details"
    );
  }
};
