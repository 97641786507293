import React from "react";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      {/* Header with image */}
      <div className="mb-8 flex flex-col items-center">
        <img
          src="./Pedd.jpg"
          alt="Pedd Logo"
          className="mb-10"
        />
        <h1 className="text-3xl font-bold text-gray-800">ยินดีต้อนรับสู่ระบบลงทะเบียน</h1>
      </div>

      {/* Links */}
      <div className="flex space-x-4">
        <Link
          to="/member-form"
          className="px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition duration-300"
        >
          สมาชิก
        </Link>
        <Link
          to="/walkin-form"
          className="px-6 py-3 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 transition duration-300"
        >
          บุคคลทั่วไป
        </Link>
      </div>
    </div>
  );
};

export default HomePage;
