import React from "react";

const Layout = ({ children }) => {
  return (
    <div
      className="min-h-screen bg-cover bg-center"
      style={{
        backgroundImage: `url('/BG.jpg')`, // Path to the image in the `public` folder
      }}
    >
      <div className="bg-white bg-opacity-75 min-h-screen">{children}</div>
    </div>
  );
};

export default Layout;
