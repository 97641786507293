import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import ProfilePage from "./components/ProfilePage";
import MemberForm from "./components/MemberForm";
import WalkInForm from "./components/WalkInForm";
import Display from "./components/Display";
import DisplayUser from "./components/DisplayUser";
import Layout from "./Style/LayOut";

const App = () => {
  return (
    <Router>
      <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/profile/:id/:userType" element={<ProfilePage />} />
        <Route path="/member-form" element={<MemberForm />} />
        <Route path="/walkin-form" element={<WalkInForm />} />
        <Route path="/display/:supporterId" element={<Display />}/>
        <Route path="/user/:id/:userType" element={<DisplayUser />}/>

      </Routes>
      </Layout>
    </Router>
  );
};

export default App;
