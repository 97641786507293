import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormInput from "./FormInput";
import { registerWalkIn } from "../services/api";

const WalkInForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    nickname: "",
    batch: "",
    phoneNumber: "",
    email: "",
    occupation: "",
  });

  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    try {
      const response = await registerWalkIn(formData);
      console.log("API Response:", response); // Log the API response
      if (response && response.walkInID) {
        navigate(`/profile/${response.walkInID}/walkin`); // Navigate to profile page
      } else {
        console.error("Walk-in ID missing in response.");
        setMessage("ไม่สามารถดึงข้อมูล ID ได้");
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setMessage("การลงทะเบียนล้มเหลว");
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmitForm}
        className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto"
      >
        <h2 className="text-xl font-bold mb-4 text-gray-800">ลงทะเบียนบุคคลทั่วไป</h2>

        {message && (
          <p
            className={`mb-4 text-center ${
              message.includes("successful") ? "text-green-500" : "text-red-500"
            }`}
          >
            {message}
          </p>
        )}

        <FormInput
          label="คำนำหน้า"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          required
        />

        <FormInput
          label="ชื่อ"
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          required
        />

        <FormInput
          label="นามสกุล"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          required
        />

        <FormInput
          label="ชื่อเล่น"
          name="nickname"
          value={formData.nickname}
          onChange={handleInputChange}
          required
        />

        <FormInput
          label="รุ่น"
          name="batch"
          value={formData.batch}
          onChange={handleInputChange}
        />

        <FormInput
          label="เบอร์โทรศัพท์"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          required
        />

        <FormInput
          label="อีเมล"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          type="email"
        />

        <FormInput
          label="อาชีพ"
          name="occupation"
          value={formData.occupation}
          onChange={handleInputChange}
        />

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md font-semibold hover:bg-blue-600 transition-colors"
        >
          ยืนยัน
        </button>
      </form>
    </div>
  );
};

export default WalkInForm;
