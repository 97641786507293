import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import { authenticateMember } from "../services/api";
import FormInput from "./FormInput"; // Import the reusable FormInput component

const MemberForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
  });
  const [message, setMessage] = useState("");
  const [memberID, setMemberID] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate function

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await authenticateMember(formData.firstName, formData.lastName);
      setMemberID(response.memberID);
      setMessage(response.message);
  
      // Redirect to profile page
      navigate(`/profile/${response.memberID}/member`);
    } catch (error) {
      setMessage("ไม่สามารถยืนยันตัวตนได้ กรุณาตรวจสอบความถูกต้อง");
      setMemberID(null);
    }
  };
  

  return (
    <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
      <h2 className="text-xl font-bold mb-4 text-gray-800">ยืนยันตัวตนสมาชิก</h2>
      
      {message && (
        <p className={`mb-4 text-center ${memberID ? "text-green-500" : "text-red-500"}`}>
          {message}
        </p>
      )}

      <FormInput
        label="ชื่อ"
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
        required
      />

      <FormInput
        label="นามสกุล"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
        required
      />

      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md font-semibold hover:bg-blue-600 transition-colors"
      >
        ตรวจสอบ
      </button>
    </form>
  );
};

export default MemberForm;
